import React from 'react';

export enum MedicationStatuses {
  FullyTaken = 'T',
  PartiallyTaken = 'PT',
  NotTaken = 'NT',
  NotObserved = 'NO',
  NotScheduled = '-',
}

const colorMap: { [key in MedicationStatuses]: string } = {
  [MedicationStatuses.FullyTaken]: 'success',
  [MedicationStatuses.PartiallyTaken]: 'warning',
  [MedicationStatuses.NotTaken]: 'error',
  [MedicationStatuses.NotObserved]: 'warning',
  [MedicationStatuses.NotScheduled]: '',
};

interface StatusIndicatorProps {
  medicationStatus: MedicationStatuses;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ medicationStatus }) => {
  const color = colorMap[medicationStatus];

  return medicationStatus === MedicationStatuses.NotScheduled ? (
    <div className="text-2xl text-center w-8 h-8">{medicationStatus}</div>
  ) : (
    <div className={`border border-${color}-700 bg-${color}-500 w-8 h-8 rounded-full text-center flex justify-center items-center`}>
      <span className={`text-${color}-800`}>{medicationStatus}</span>
    </div>
  );
};

export default StatusIndicator;
