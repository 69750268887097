import React, { useState } from 'react';
import { MarChartData, MedPlanResults } from '__generated__/graphql';
import { endOfDay, fromUnixTime, getMonth, getYear, startOfDay } from 'date-fns';
import { RRule } from 'rrule';
import { getPeriodForHour, PeriodRange, isTimeInPeriod, isTimeBetweenVisit } from 'services/visitPlan';
import StatusIndicator, { MedicationStatuses } from './StatusIndicator';
import Modal from './Modal';

const medicationStatusMap: Record<string, MedicationStatuses> = {
  Taken: MedicationStatuses.FullyTaken,
  'Partially taken': MedicationStatuses.PartiallyTaken,
  'Not taken': MedicationStatuses.NotTaken,
  'Not observed': MedicationStatuses.NotObserved,
};

interface TableProps {
  marChartData: MarChartData[] | null;
  monthStartTimestamp: number;
}

const daysInMonthFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  return new Date(year, month, 0).getUTCDate();
};

interface DoseStatus {
  day: number;
  status: string | null;
  record: MedPlanResults | undefined;
  doseNumber: number;
  time: string | null;
}

function getTimeFromRRule(
  rruleString: string | undefined,
  doseIndex: number,
  isExactTime: boolean,
): { displayTime: string; hour: number; minute: number } | null {
  if (!rruleString) return null;

  try {
    const rrule = RRule.fromString(rruleString);
    const { options } = rrule;

    if (options.byhour && options.byhour.length > doseIndex) {
      const hour = options.byhour[doseIndex];
      const minute = options.byminute && options.byminute.length > doseIndex ? options.byminute[doseIndex] : options.byminute?.[0] ?? 0;

      if (!isExactTime) {
        const period = getPeriodForHour(hour);
        return period ? { displayTime: period, hour, minute } : null;
      }

      return {
        displayTime: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
        hour,
        minute,
      };
    }
  } catch (error) {
    console.error('Error parsing RRule:', error);
  }

  return null;
}

function getMedicationStatusesForMonth(dim: number[], medication: MarChartData, unixTimestamp: number): DoseStatus[][] {
  const date = fromUnixTime(unixTimestamp / 1000);
  const year = getYear(date);
  const month = getMonth(date);

  // Get all possible doses from RRule
  let doseCount = 0;
  try {
    const rrule = RRule.fromString(medication.rrule ?? '');
    doseCount = rrule.options.byhour?.length ?? 0;
  } catch (error) {
    console.error('Error parsing RRule:', error);
  }

  // Create array of dose rows
  return Array.from({ length: doseCount }, (_, doseIndex) => {
    const timeInfo = medication.type !== 'PRN' && medication.rrule ? getTimeFromRRule(medication.rrule, doseIndex, medication.isExactTime ?? false) : null;

    return dim.map((day) => {
      const startOfDayTimestamp = startOfDay(new Date(Date.UTC(year, month, day))).getTime();
      const endOfDayTimestamp = endOfDay(new Date(Date.UTC(year, month, day))).getTime();

      // Get all records for this day
      const dayRecords = medication.medPlanResults?.filter(
        (res) => res?.visitStartDateTime && res.visitStartDateTime >= startOfDayTimestamp && res.visitStartDateTime <= endOfDayTimestamp && res.status,
      );

      let matchingRecord;
      if (medication.type !== 'PRN' && timeInfo && dayRecords) {
        matchingRecord = medication.isExactTime
          ? dayRecords.find(
              (record) =>
                record?.visitStartDateTime &&
                record.visitEndDateTime &&
                isTimeBetweenVisit({ hour: timeInfo.hour, minute: timeInfo.minute }, record.visitStartDateTime, record.visitEndDateTime),
            )
          : dayRecords.find((record) => record?.visitStartDateTime && isTimeInPeriod(record.visitStartDateTime, timeInfo.displayTime as PeriodRange));
      } else {
        matchingRecord = dayRecords?.[doseIndex];
      }

      return {
        day,
        status: matchingRecord?.status ?? null,
        record: matchingRecord || undefined,
        doseNumber: doseIndex + 1,
        time: timeInfo?.displayTime ?? null,
      };
    });
  });
}

const Table: React.FC<TableProps> = ({ marChartData, monthStartTimestamp }) => {
  const daysInMonth = Array.from({ length: daysInMonthFromTimestamp(monthStartTimestamp) }, (_, i) => i + 1);
  const [selectedRecord, setSelectedRecord] = useState<MedPlanResults | undefined>(undefined);
  const [selectedMedication, setSelectedMedication] = useState<MarChartData | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return marChartData?.length === 0 ? (
    <div className="w-full flex justify-center items-center mt-36">There is no data for this month and support worker</div>
  ) : (
    <div>
      <div className="overflow-x-auto mt-4">
        <table className="border-collapse border border-gray-300 table-auto">
          <thead>
            <tr className="text-left">
              <th className="w-fit bg-gray-100 p-2 border border-gray-300">Medication</th>
              <th className="w-fit bg-gray-100 p-2 border border-gray-300">Dose</th>
              <th className="w-fit bg-gray-100 p-2 border border-gray-300">Time</th>
              {daysInMonth.map((day) => (
                <th key={day} className="p-2 border border-gray-300 text-center font-light">
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {marChartData?.map((medication) => {
              const doseRows = getMedicationStatusesForMonth(daysInMonth, medication, monthStartTimestamp);

              return doseRows.map((doseRow, doseIndex) => (
                <tr key={`${medication.name}-dose-${doseIndex + 1}`} className="font-light">
                  {doseIndex === 0 ? (
                    <td rowSpan={doseRows.length} className="p-2 border border-gray-300">
                      <div className="flex flex-col gap-2">
                        <div>{medication.name}</div>
                        <div className="text-xs text-gray-500">{medication.type}</div>
                      </div>
                    </td>
                  ) : null}
                  <td className="p-2 border border-gray-300">{doseRow[0].doseNumber}</td>
                  <td className="p-2 border border-gray-300">{medication.type !== 'PRN' && doseRow[0].time ? doseRow[0].time : ''}</td>
                  {doseRow.map((d) => (
                    <td
                      key={d.day}
                      className={`p-2 border-y border-r border-l-0 border-gray-300 text-center ${d.status ? 'cursor-pointer' : ''}`}
                      aria-label={d.day.toString()}
                      onClick={() => {
                        if (d.status) {
                          setSelectedRecord(d.record);
                          setSelectedMedication(medication);
                          setIsModalOpen(true);
                        }
                      }}
                    >
                      <StatusIndicator medicationStatus={d.status ? medicationStatusMap[d.status] : MedicationStatuses.NotScheduled} />
                    </td>
                  ))}
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
      {selectedRecord && selectedMedication && (
        <Modal medPlanResults={selectedRecord} medication={selectedMedication} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
};

export default Table;
