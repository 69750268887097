import { getHours, getMinutes } from 'date-fns';

export enum PeriodRange {
  Morning = 'Morning',
  Lunch = 'Lunch',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}

interface TimeRange {
  start: number; // minutes since midnight
  end: number; // minutes since midnight
}

const PERIOD_RANGES: Record<PeriodRange, TimeRange> = {
  [PeriodRange.Morning]: { start: 0, end: 719 }, // 00:00-11:59
  [PeriodRange.Lunch]: { start: 720, end: 839 }, // 12:00-13:59
  [PeriodRange.Afternoon]: { start: 840, end: 1079 }, // 14:00-17:59
  [PeriodRange.Evening]: { start: 1080, end: 1439 }, // 18:00-23:59
};

const PERIOD_HOURS: Record<number, PeriodRange> = {
  8: PeriodRange.Morning,
  12: PeriodRange.Lunch,
  16: PeriodRange.Afternoon,
  20: PeriodRange.Evening,
};

export function getMinutesSinceMidnight(date: number): number {
  const hours = getHours(date);
  const minutes = getMinutes(date);
  return hours * 60 + minutes;
}

export function isTimeInPeriod(timestamp: number, period: PeriodRange): boolean {
  const minutes = getMinutesSinceMidnight(timestamp);
  const range = PERIOD_RANGES[period];
  return minutes >= range.start && minutes <= range.end;
}

export function getPeriodForHour(hour: number): PeriodRange | null {
  return PERIOD_HOURS[hour] || null;
}

export function isTimeBetweenVisit(time: { hour: number; minute: number }, visitStart: number, visitEnd: number): boolean {
  const timeMinutes = time.hour * 60 + time.minute;
  const startMinutes = getMinutesSinceMidnight(visitStart);
  const endMinutes = getMinutesSinceMidnight(visitEnd);
  return timeMinutes >= startMinutes && timeMinutes <= endMinutes;
}
