import React from 'react';
import ReactSelect, { SingleValue } from 'react-select';

interface SelectProps {
  placeHolder: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
  onSelectChange?: (value: string) => void;
  value?: string;
}

const Select: React.FC<SelectProps> = ({ placeHolder, options, disabled, onSelectChange, value }) => {
  const selectedOption = options.find((option) => option.value === value);
  const handleChange = (newValue: SingleValue<{ value: string; label: string }>) => {
    onSelectChange && onSelectChange(newValue ? newValue.value : '');
  };

  return (
    <ReactSelect
      placeholder={placeHolder}
      options={options}
      isClearable={true}
      onChange={handleChange}
      value={selectedOption || null}
      isDisabled={typeof disabled === 'undefined' ? false : disabled}
      styles={{
        input: (styles) => ({
          ...styles,
          paddingRight: '10rem',
        }),
      }}
    />
  );
};

export default Select;
