import { gql, useLazyQuery } from '@apollo/client';
import { MarChartData, MarChartDataInput } from '__generated__/graphql';

export const GET_MAR_CHART_DATA = gql`
  query GetMarChartData($query: MarChartDataInput) {
    GetMarChartData(query: $query) {
      dose
      howToTake
      name
      isExactTime
      description
      rrule
      support
      type
      medPlanResults {
        teamId
        customerId
        customerMedicationId
        administrationNotes
        status
        notTakenReason
        supportWorkerName
        support
        type
        notes
        dateUpdated
        visitStartDateTime
        visitEndDateTime
      }
    }
  }
`;

interface GetMarChartResponse {
  GetMarChartData: MarChartData[];
}

export const useGetMarChartData = (query: MarChartDataInput, skip?: boolean) => {
  const [getMarChartData, { data, loading, error }] = useLazyQuery<GetMarChartResponse>(GET_MAR_CHART_DATA, {
    variables: {
      skip,
      query,
    },
  });

  return { getMarChartData, marChartData: data?.GetMarChartData || [], marChartDataLoading: loading, error };
};
