import { MarChartData, MedPlanResults } from '__generated__/graphql';
import { RRule } from 'rrule';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import React from 'react';

interface ModalProps {
  medPlanResults: MedPlanResults;
  medication: MarChartData;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Converts an RRule string to human readable frequency text
 * @param rruleStr - The RRule string to parse
 * @returns Human readable frequency text
 */
const getFrequencyText = (rruleStr: string): string => {
  try {
    const rrule = RRule.fromString(rruleStr.split('\n')[1]);
    if (rrule.options.interval > 1) {
      return `Every ${rrule.options.interval} ${rrule.options.freq === RRule.WEEKLY ? 'weeks' : 'days'}`;
    }
    if (rrule.options.freq === RRule.WEEKLY) {
      return 'Weekly';
    }
    return 'Daily';
  } catch (error) {
    console.error('Error parsing RRule:', error);
    return 'Schedule not available';
  }
};

/**
 * Gets the status indicator color based on medication status
 * @param status - The medication status
 * @returns CSS color class
 */
const getStatusColor = (status: string | null | undefined): string => {
  switch (status) {
    case 'Taken':
      return 'bg-success-600';
    case 'Partially taken':
    case 'Not observed':
      return 'bg-warning-600';
    case 'Not taken':
      return 'bg-error-600';
    default:
      return 'bg-gray-600';
  }
};

const Modal: React.FC<ModalProps> = ({ medPlanResults, medication, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-3xl mx-4">
        <div className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">{medication.name}</h2>
              <p className="text-sm text-gray-600">
                {medPlanResults.visitStartDateTime && medPlanResults.visitEndDateTime
                  ? `${format(new Date(medPlanResults.visitStartDateTime), 'dd/MM/yyyy @ HH:mm')} - ${format(
                      new Date(medPlanResults.visitEndDateTime),
                      'HH:mm',
                    )}`
                  : ''}
              </p>
            </div>
            <button type="button" onClick={onClose} className="text-gray-500 hover:text-gray-700" aria-label="Close modal">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-6">
            <h3 className="font-semibold text-gray-900">Result</h3>
            <div className="mt-2">
              <div className="flex items-center gap-1">
                <span className="text-gray-700 mr-2">Outcome</span>
                <div className={`h-3 w-3 rounded-full ${getStatusColor(medPlanResults.status)}`} />
                <span>{medPlanResults.status || 'Unknown'}</span>
              </div>
              {medPlanResults.notTakenReason && (
                <div className="mt-2 flex items-center gap-3">
                  <span className="text-gray-700">Reason</span>
                  <p>{medPlanResults.notTakenReason}</p>
                </div>
              )}
              {medPlanResults.administrationNotes && (
                <div className="mt-2 flex items-center gap-3">
                  <span className="text-gray-700">Notes</span>
                  <p>{medPlanResults.administrationNotes}</p>
                </div>
              )}
              <div className="mt-2 flex items-center gap-3">
                <span className="text-gray-700">Carer</span>
                <p>{medPlanResults.supportWorkerName || 'Not assigned'}</p>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="font-semibold text-gray-900">Medication</h3>
            <div className="mt-2">
              <div className="flex items-center gap-2">
                <span className="text-gray-700">Frequency</span>
                <span>{medication.rrule ? getFrequencyText(medication.rrule) : 'Not specified'}</span>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <span className="text-gray-700">Dose</span>
                <span>{medication.dose || 'Not specified'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
