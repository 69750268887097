import React, { useEffect, useState } from 'react';
import { getUnixTime, startOfMonth } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import Loading from 'components/Loading';
import { useGetMarChartData } from 'api/hooks/useMarChartData';
import { useGetCustomers } from 'api/hooks/useCustomers';
import useGetTeams from 'api/hooks/useGetTeams';
import Select from './components/Select';
import Table from './components/Table';

const getMonthStartTimestamp = (year: number, month: number) => {
  if (month < 0 || month > 11) {
    throw new Error('Month must be between 0 and 11');
  }

  const date = new Date(year, month, 1);
  return getUnixTime(startOfMonth(date)) * 1000;
};

const years = ['2019', '2021', '2022', '2023', '2024', '2025'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

interface MarChartProps {}

const MarChart: React.FC<MarChartProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [teamId, setTeamId] = useState<string>(searchParams.get('teamId') || '');
  const [customerId, setCustomerId] = useState<string>(searchParams.get('customerId') || '');
  const [selectedYear, setSelectedYear] = useState<string>(searchParams.get('year') || '');
  const [selectedMonth, setSelectedMonth] = useState<string>(searchParams.get('month') || '');

  const { customers } = useGetCustomers({ showAll: false, teamId });
  const { teams, teamsLoading } = useGetTeams();

  const monthStartTimestamp = getMonthStartTimestamp(Number.parseInt(selectedYear, 10), Number.parseInt(selectedMonth, 10));

  const { getMarChartData, marChartData, marChartDataLoading } = useGetMarChartData({
    customerId,
    teamId,
    queryDate: monthStartTimestamp,
  });

  console.log('marChartDataLoading', marChartDataLoading);

  useEffect(() => {
    if (customerId !== '' && teamId !== '' && selectedYear !== '' && selectedMonth !== '') {
      console.log('getMarChartData');
      getMarChartData();
    }
  }, [teamId, customerId, selectedYear, selectedMonth, getMarChartData]);

  const updateUrlParams = (updates: Record<string, string>) => {
    const newParams = new URLSearchParams(searchParams);

    Object.entries(updates).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
    });

    setSearchParams(newParams);
  };

  const handleTeamChange = (value: string) => {
    setTeamId(value);
    updateUrlParams({
      teamId: value,
      customerId: '',
      year: '',
      month: '',
    });
    setCustomerId('');
    setSelectedYear('');
    setSelectedMonth('');
  };

  const handleCustomerChange = (value: string) => {
    setCustomerId(value);
    updateUrlParams({
      customerId: value,
      year: '',
      month: '',
    });
    setSelectedYear('');
    setSelectedMonth('');
  };

  const handleYearChange = (value: string) => {
    setSelectedYear(value);
    updateUrlParams({
      year: value,
      month: '',
    });
    setSelectedMonth('');
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
    updateUrlParams({
      month: value,
    });
  };

  const selectData = [
    {
      id: 'team-name',
      placeHolder: 'Team Name',
      options: teams.map((team) => ({ value: team.teamId, label: team.teamName })),
      onSelectChange: handleTeamChange,
      value: teamId,
    },
    {
      id: 'supported-person-name',
      placeHolder: 'Supported Person Name',
      options: customers?.map((customer) => ({ value: customer.id, label: `${customer.firstName} ${customer.lastName}` })),
      onSelectChange: handleCustomerChange,
      disabled: !teamId,
      value: customerId,
    },
    {
      id: 'year',
      placeHolder: 'Year',
      options: years?.map((year) => ({ value: year, label: year })),
      onSelectChange: handleYearChange,
      disabled: !teamId || !customerId,
      value: selectedYear,
    },
    {
      id: 'month',
      placeHolder: 'Month',
      options: months?.map((month, index) => ({ value: index.toString(), label: month })),
      onSelectChange: handleMonthChange,
      disabled: !teamId || !customerId || !selectedYear,
      value: selectedMonth,
    },
  ];

  if (teamsLoading) {
    return <Loading />;
  }

  return (
    <div className="m-8">
      <div className="flex flex-row space-x-4">
        {selectData.map((sd) => (
          <Select {...sd} key={sd.id} />
        ))}
      </div>
      {marChartDataLoading ? (
        <div className="mt-36">
          <Loading isComponent={true} />
        </div>
      ) : (
        <Table marChartData={marChartData} monthStartTimestamp={monthStartTimestamp} />
      )}
    </div>
  );
};

export default MarChart;
